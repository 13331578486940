import React, { useEffect, useState, useCallback } from 'react';
import './App.css';
import image1 from './image1.jpg';
import image2 from './image2.jpg';
import image3 from './image3.jpg';
import image4 from './image4.jpg';
import image5 from './image5.jpg';
import insta from './insta.svg';

function App() {
  const initialX = (window.innerWidth - 280) / 2;
  const initialY = (window.innerHeight - 650) / 2;

  const [state, setState] = useState({
    position: { x: initialX, y: initialY },
    secondPosition: { x: initialX + 5, y: initialY + 5 },
    thirdPosition: { x: initialX + 10, y: initialY + 10 },
    fourthPosition: { x: initialX + 15, y: initialY + 15 },
    fifthPosition: { x: initialX + 20, y: initialY + 20 },
    isLeft: 0,
    initialPosition: { x: 0, y: 0 },
  });

  const [dragging, setDragging] = useState(false);
  const [hasDragged, setHasDragged] = useState(false);

  const [imageSize, setImageSize] = useState({ width: 280, height: 500 });

  const { position, secondPosition, thirdPosition, fourthPosition, fifthPosition, isLeft } = state;

  const [clickedIndex, setClickedIndex] = useState(null);

  const handleClick = useCallback((index) => {
    setClickedIndex(prevClickedIndex => {
        if (prevClickedIndex === index) {
          setImageSize({ width: 280, height: 500 }); // reset size
          return null;
        } else {
          setImageSize({ width: 500, height: 500 }); // increase width
          return index;
        }
    });
}, []);

const textColumns = [
  ['', 'Together with their Families', '', 'Deeply grateful to all who shared our special day. Your presence enriched our memories. Heartfelt thanks for being part of our joyous journey.', '', 'Table'],
  ['', 'Remarkable Events', '', 'Morning Ceremony', '1100am Kaikan Ceremony', '1200pm Lunch', '1300pm', '', 'Dinner Together', '1900pm Reception Open', '1930pm March-in', '1935pm Welcome Speech', '1945pm Dinner Begin', '2015pm Interaction', '2030pm Meals - in', '2100pm Finale', '2300pm Say Goodbye'],
  ['', 'Formal Attire', '', 'Coat and Tie for the Gentlemen', 'Cocktail Dress and Werdge Heels for the Ladies.', '', 'White is reserved for the bride', '', 'Color tones for the night', 'Color'],
  ['', 'Social Media', '', 'Please tag us on {insta}Instagram', '@crazier_ves_jin', '@bernice.weii', '', '#嫁进豪门', '#Bernice'],
  ['', 'Special Thanks', '', 'A big cheerful thank you to our amazing friends and family who have made our wedding dreams come true! Your love and support shine brighter than the sun and made this day extra special.', '', 'Principal Sponsor', 'Sponsor', '', 'Grooms Bride', 'Names', '', 'Flower Girls', 'Names', '', 'Working behind the curtain', 'Names', '', 'Lovesss from', 'Countries'],
];

const title = [
  ['Ves Jin & Bernice Lim', 'Together with their Families'],
  ['Ves Jin & Bernice Lim', 'Remarkable Events'],
  ['Ves Jin & Bernice Lim', 'Formal Attire'],
  ['Ves Jin & Bernice Lim', 'Social Media'],
  ['Ves Jin & Bernice Lim', 'Special Thanks'],
];

  const handleStartDrag = useCallback((clientX, clientY) => {	
    if (clickedIndex !== null) return;  // Disable dragging if clickedIndex is not null
    setDragging(true);	
    setState((prevState) => ({	
      ...prevState,	
      initialPosition: { x: clientX, y: clientY },	
    }));	
  }, [clickedIndex]);	
  const handleDoDrag = useCallback(	
    (clientX, clientY) => {	
      if (clickedIndex !== null) return;  // Disable dragging if clickedIndex is not null
      if (!dragging) return;	
      const dx = clientX - state.initialPosition.x;	
      setHasDragged(true);  // New line for setting hasDragged to true	
      if (dx < -10 && isLeft === 0) {	
        setState({	
          ...state,	
          isLeft: 1,	
          position: { x: initialX - 50, y: initialY - 50 },	
          secondPosition: { x: initialX, y: initialY },	
        });	
        setDragging(false);	
      } else if (dx < -10 && isLeft === 1) {	
        setState({	
          ...state,	
          isLeft: 2,	
          secondPosition: { x: initialX - 50, y: initialY - 50 },	
          thirdPosition: { x: initialX, y: initialY },	
        });	
        setDragging(false);	
      } else if (dx < -10 && isLeft === 2) {	
        setState({	
          ...state,	
          isLeft: 3,	
          thirdPosition: { x: initialX - 50, y: initialY - 50 },	
          fourthPosition: { x: initialX, y: initialY },	
        });	
        setDragging(false);	
      } else if (dx < -10 && isLeft === 3) {	
        setState({	
          ...state,	
          isLeft: 4,	
          fourthPosition: { x: initialX - 50, y: initialY - 50 },	
          fifthPosition: { x: initialX, y: initialY },	
        });	
        setDragging(false);	
      }	
      if (dx > 10 && isLeft === 1) {	
        setState({	
          ...state,	
          isLeft: 0,	
          position: { x: initialX, y: initialY },	
          secondPosition: { x: initialX + 5, y: initialY + 5 },	
          thirdPosition: { x: initialX + 10, y: initialY + 10 },	
          fourthPosition: { x: initialX + 15, y: initialY + 15 },	
          fifthPosition: { x: initialX + 20, y: initialY + 20 },	
        });	
        setDragging(false);	
      } else if (dx > 10 && isLeft === 2) {	
        setState({	
          ...state,	
          isLeft: 1,	
          secondPosition: { x: initialX, y: initialY },	
          thirdPosition: { x: initialX + 10, y: initialY + 10 },	
          fourthPosition: { x: initialX + 15, y: initialY + 15 },	
          fifthPosition: { x: initialX + 20, y: initialY + 20 },	
        });	
        setDragging(false);	
      } else if (dx > 10 && isLeft === 3) {	
        setState({	
          ...state,	
          isLeft: 2,	
          thirdPosition: { x: initialX, y: initialY },	
          fourthPosition: { x: initialX + 15, y: initialY + 15 },	
          fifthPosition: { x: initialX + 20, y: initialY + 20 },	
        });	
        setDragging(false);	
      } else if (dx > 10 && isLeft === 4) {	
        setState({	
          ...state,	
          isLeft: 3,	
          fourthPosition: { x: initialX, y: initialY },	
          fifthPosition: { x: initialX + 20, y: initialY + 20 },	
        });	
        setDragging(false);	
      }	
    },	
    [dragging, isLeft, initialX, initialY, state, clickedIndex]	
  );	
  const handleEndDrag = useCallback(() => {	
    if (clickedIndex !== null) return;  // Disable dragging if clickedIndex is not null
    setDragging(false);	
  }, [clickedIndex]);	

  useEffect(() => {	

    const handleMouseDown = (e) => handleStartDrag(e.clientX, e.clientY);	
    const handleMouseMove = (e) => handleDoDrag(e.clientX, e.clientY);	
    const handleMouseUp = handleEndDrag;	
    const handleTouchStart = (e) => handleStartDrag(e.touches[0].clientX, e.touches[0].clientY);	
    const handleTouchMove = (e) => handleDoDrag(e.touches[0].clientX, e.touches[0].clientY);	
    const handleTouchEnd = handleEndDrag;	
    window.addEventListener('mousedown', handleMouseDown);	
    window.addEventListener('mousemove', handleMouseMove);	
    window.addEventListener('mouseup', handleMouseUp);	
    window.addEventListener('touchstart', handleTouchStart, { passive: false });	
    window.addEventListener('touchmove', handleTouchMove, { passive: false });	
    window.addEventListener('touchend', handleTouchEnd);	

    return () => {	
      window.removeEventListener('mousedown', handleMouseDown);	
      window.removeEventListener('mousemove', handleMouseMove);	
      window.removeEventListener('mouseup', handleMouseUp);	
      window.removeEventListener('touchstart', handleTouchStart);	
      window.removeEventListener('touchmove', handleTouchMove);	
      window.removeEventListener('touchend', handleTouchEnd);	
    };	
  }, [handleStartDrag, handleDoDrag, handleEndDrag]);

  return (
    <div className={`App ${clickedIndex !== null ? 'fade-background' : ''} ${(clickedIndex === 4) ? 'overflow-scroll' : 'overflow-hidden'}`}>

      {[position, secondPosition, thirdPosition, fourthPosition, fifthPosition].map((pos, index) => {
        const calculatedOpacity = (clickedIndex === null || clickedIndex === index) ? 1 : 0;

        let calculatedZIndex;
        if (hasDragged) {
          if (isLeft === 0) {
            calculatedZIndex = 4 - index;
          } else if (isLeft > 0 && index >= isLeft) {
            calculatedZIndex = 5 - (index - isLeft);
          } else {
            calculatedZIndex = index;
          }
        } else {
          calculatedZIndex = 4 - index;
        }

        const shouldMoveUp = clickedIndex === index;


        return (
        <div 
          key={index} 
          style={{
            position: 'absolute',
            zIndex: calculatedZIndex, 
            top: shouldMoveUp ? `${pos.y + 5 * index - 250}px` : `${pos.y + 5 * index}px`,
            transition: 'top 0.5s ease-in-out'
          }}
        >

          <div
              style={{
                width: '100%', // Full width
                fontFamily: 'Lancelot, serif',
                background: 'linear-gradient(to right, rgba(128, 128, 128, 0.3), rgba(128, 128, 128, 0.3))', // Gradient from half-transparent orange to full opaque orange
                color: 'white', // Text color
                padding: '10px 0', // Vertical padding
                textAlign: 'center', // Center the text horizontally
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Add a subtle shadow
                transition: 'opacity 0.5s ease-in-out',
                opacity: index === isLeft ? 1 : 0,
                zIndex: calculatedZIndex + 1,
                display: 'flex', // Use flexbox to align content
                flexDirection: 'column', // Stack children vertically
                justifyContent: 'center', // Center children vertically
                alignItems: 'center', // Center children horizontally
                transform: 'translateY(-70px)'

              }}
            >
            <div className='special'>{title[index][0]}</div>
            <div className='font-size-20-white'>{title[index][1]}</div>
          </div>

          <div
            className="draggable"
            style={{
              backgroundImage: `url(${[image1, image2, image3, image4, image5][index]})`, // This sets the image
              backgroundSize: 'cover',
              width: `${imageSize.width}px`,
              height: `${imageSize.height}px`,
              left: shouldMoveUp ? `${pos.x + 5 * index - 80}px` : `${pos.x + 5 * index}px`,  // move 50px to the left
              position: 'relative',
              opacity: calculatedOpacity,
              transition: 'left 0.5s ease-in-out, width 0.5s ease-in-out',
              borderRadius: '10px'  // This line makes the corners rounded

            }}
            onClick={index === isLeft ? () => handleClick(index) : null}
          ></div>

          {/* Text columns */}
          <div
            style={{
              fontFamily: 'Lancelot, serif',
              position: 'relative',
              height: 'auto',
              width: '200px',
              display: 'flex',
              flexDirection: index === 0 ? 'column' : 'row',
              justifyContent: 'space-between',
              transition: 'opacity 0.5s ease-in-out',
              opacity: shouldMoveUp ? 1 : 0,
              zIndex: calculatedZIndex + 1,
              marginLeft: '50px'
            }}
          >
        {index === 0 ? 
            textColumns[index].map((text, textIndex) => {
              if (text === '') {
                return <div key={textIndex} style={{ height: '1em' }}></div>;  // adjust '1em' if you want a larger or smaller space
              } else if (textIndex === 1) {
                return <div className="font-size-24" key={textIndex} style={{ width: '300px' }}>{text}</div>;
              } else if (textIndex === 3) {
                return <div className="font-size-15" key={textIndex} style={{ width: '300px' }}>{text}</div>;
              } else if (textIndex === 5) {
                return (
                  <table key={textIndex}>
                    <tbody>
                      <tr>
                        <td className="font-size-20">{'Ves Jin'}</td>
                        <td className="font-size-20">{'Bernice Lim'}</td>
                      </tr>
                      <tr>
                        <td className='font-size-15'>{'Lily Lim'}</td>
                        <td className='font-size-15'>{'Alfred Lim'}<br />{'Jenny Lai'}</td>
                      </tr>
                    </tbody>
                  </table>
                );
              } else {
                return <div key={textIndex}>{text}</div>;
              }
            })
      : index === 1 ? 
      <div style={{ flexDirection: 'column' }}>

      {textColumns[index].map((text, textIndex) => {
        if (text === '') {
          return <div key={textIndex} style={{ height: '1em' }}></div>;  // adjust '1em' if you want a larger or smaller space
        } else if (textIndex === 1) {
          return <div className="font-size-25" key={textIndex} style={{ width: '300px' }}>{text}</div>;
        } else if (textIndex === 3 || text === 'Dinner Together') { // Check if the text is "Morning Ceremony" or "Dinner Together"
          return <div className="font-size-15" key={textIndex} style={{ width: '300px', textDecoration: 'underline' }}>{text}</div>; // added textDecoration: 'underline' here
        } else {
          return <div className="font-size-15" key={textIndex}>{text}</div>;
        }
      })}

      </div>
      : index === 2 ? 
      <div style={{ flexDirection: 'column' }}>

      {textColumns[index].map((text, textIndex) => {
        if (text === '') {
          return <div key={textIndex} style={{ height: '1em' }}></div>;  // adjust '1em' if you want a larger or smaller space
        } else if (textIndex === 1) {
          return <div className="font-size-25" key={textIndex} style={{ width: '300px' }}>{text}</div>;
        } else if (textIndex === 9) {
          return (
              <div key={textIndex} style={{ display: 'flex', paddingTop: '10px'  }}>
                  <div style={{ width: '50px', height: '50px', backgroundColor: '#DEC2B5' }}></div> {/* SaddleBrown */}
                  <div style={{ width: '50px', height: '50px', backgroundColor: '#906350' }}></div> {/* Brown */}
                  <div style={{ width: '50px', height: '50px', backgroundColor: '#B08D79' }}></div> {/* Tan */}
                  <div style={{ width: '50px', height: '50px', backgroundColor: '#F5E0D1' }}></div> {/* SandyBrown */}
              </div>
          )
        } else {
          return <div className="font-size-15" key={textIndex}>{text}</div>;
        }
      })}

      </div>
      : index === 3 ? 
      <div style={{ flexDirection: 'column' }}>

      {textColumns[index].map((text, textIndex) => {
        if (text === '') {
          return <div key={textIndex} style={{ height: '1em' }}></div>;  // adjust '1em' if you want a larger or smaller space
        } else if (textIndex === 1) {
          return <div className="font-size-25" key={textIndex} style={{ width: '300px' }}>{text}</div>;
        } else if (textIndex === 3) {
          return <div className="font-size-15" key={textIndex} style={{ width: '300px' }}>Please tag us on <img src={insta} alt="" style={{ width: '24px', height: '24px', verticalAlign: 'middle' }} /> Instagram</div>;
        } else {
          return <div className="font-size-15" key={textIndex}>{text}</div>;
        }
      })}

      </div>
      : index === 4 ? 
      <div style={{ flexDirection: 'column' }}>

      {textColumns[index].map((text, textIndex) => {
        if (text === '') {
          return <div key={textIndex} style={{ height: '1em' }}></div>;  // adjust '1em' if you want a larger or smaller space
        } else if (textIndex === 1) {
          return <div className="font-size-25" key={textIndex} style={{ width: '300px', textAlign: 'center' }}>{text}</div>;
        } else if (textIndex === 5 || textIndex === 8 || textIndex === 11 || textIndex === 17) {
          return <div className="font-size-25" key={textIndex} style={{ width: '300px', textAlign: 'center', textDecoration: 'underline' }}>{text}</div>;
        } else if (textIndex === 14) {
          return <div className="font-size-24" key={textIndex} style={{ width: '300px', textAlign: 'center', textDecoration: 'underline' }}>{text}</div>;
        } else if (textIndex === 6) {
          return (
            <table key={textIndex}>
              <tbody>
                <tr>
                  <td className="font-size-20">{'EGT Global Logistics'}</td>
                  <td className="font-size-20">{'I Love Supplements'}</td>
                </tr>
              </tbody>
            </table>
          );
        } else if (textIndex === 9) {
          return (
            <table key={textIndex}>
              <tbody>
                <tr>
                  <td>{'Alex Ho'}<br />{'Andrei Moriyama'}<br />{'Andrei Nakagawa'}<br />{'Nicolas Kan'}<br />{'Yuki Sakurai'}<br />{'Zac Foo'}</td>
                  <td>{'Jia Thoong'}<br />{'Jia Yen'}<br />{'Jia Yee'}<br />{'Lenna Teh'}<br />{'Tracey Leong'}<br />{'Vincci'}</td>
                </tr>
              </tbody>
            </table>
          );
        } else if (textIndex === 12) {
          return (
            <table key={textIndex}>
              <tbody>
                <tr>
                  <td>{'Riyun Jeong'}<br />{'Riyun Jeong'}</td>
                </tr>
              </tbody>
            </table>
          );
        } else if (textIndex === 15) {
          return (
            <table key={textIndex}>
              <tbody>
                <tr>
                  <td>{'Decorator___Ronnie Jin'}<br />{'Photographer___Everyone'}<br />{'Camera Man___'}<br />{'Videographer___Carmen'}<br />{'Emcee___Jian Hong'}</td>
                </tr>
              </tbody>
            </table>
          );
        } else if (textIndex === 18) {
          return (
            <table key={textIndex}>
              <tbody>
                <tr>
                  <td>{'New Zealand'}<br />{'Australia'}<br />{'Thailand'}<br />{'Taiwan'}<br />{'Brazil'}<br />{'Korea'}<br />{'Japan'}<br />{'Singapore'}<br />{'Sarawak & Sabah'}<br />{'All around west Malaysia'}</td>
                </tr>
              </tbody>
            </table>
          );
        } else {
          return <div key={textIndex}>{text}</div>;
        }
      })}

      </div>
      :
      (
        <>
          <div>{textColumns[index][0]}</div>
          <div>{textColumns[index][1]}</div>
        </>
      )
    }
  </div>
</div>

      );
    })}
  </div>
);
}

export default App;